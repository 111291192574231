body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.no-hover-decoration {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.three-d-text-container {
  perspective: 1000px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-d-text {
  font-size: 2em;
  font-weight: bold;
  padding: 20px;
  background-color: #9b6e6e;
  color: white;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out;
}

#canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* Make the container cover the full width of the viewport */
  height: 100vh;
  /* Make the container cover the full height of the viewport */
  z-index: -1;
  /* Place the canvas behind other content */
}

.grow-on-hover-box {
  transition: transform 2.0s cubic-bezier(0, 0.88, .12, 1);
  cursor: pointer;
  border: 1px solid;
  text-decoration: none;
}

.grow-on-hover-box:hover {
  transform: scale(1.05) translateZ(0);
  text-decoration: none;
}

.img {
  object-fit: contain;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 0.25s ease-in-out, transform 2s cubic-bezier(0, 0.88, .12, 1);
}

.fade-in-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.short-text {
  line-clamp: 2;
}


h3 {
  padding-top: 24px;
}

.gradientText {
  background: linear-gradient(90deg, #00ff00, #0000ff, #ff0000, #00ff00);
  background-size: 500% 50%;
  background-clip: text;
  -webkit-background-clip: text;
  /* For Webkit browsers */
  color: transparent;
  /* Make text invisible and rely on background for color */
  animation: Animation 20s linear infinite;
}

.chroma-shadow {
  box-shadow: 0px 0px 0px rgb(255, 255, 255);

  &:hover {
    box-shadow: 5px 5px 0px rgb(255, 255, 255);
  }

  transition: box-shadow 0.25s cubic-bezier(0, 0.88, 0.12, 1);

}

@keyframes Animation {
  0% {
    background-position: 250% 0%
  }

  100% {
    background-position: 0% 0%
  }
}

.luminosity {
  mix-blend-mode: luminosity;
}

.screen {
  mix-blend-mode: screen;
}

.copy {
  max-width: 700px;
  line-height: 2;
  font-size: 1.1rem;
}

.rounded {
  border-radius: 10px;
}


.shadow {
  width: 100%;
  border-radius: 10px;
  position: relative;
  background: linear-gradient(0deg, #000, #262626);
  line-height: 0;

  &:hover::after {
    filter: blur(10px);
  }


}

.shadow:before,
.shadow:after {
  content: '';
  position: absolute;
  border-radius: 10px;
  top: -1px;
  left: -1px;
  background: linear-gradient(90deg, #00ff00, #0000ff, #ff0000, #00ff00);
  background-size: 500% 50%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
  animation: animate 30s linear infinite;
  -webkit-transition: 0.5s -webkit-filter cubic-bezier(0, 0.88, .12, 1);
  -moz-transition: 0.5s -moz-filter cubic-bezier(0, 0.88, .12, 1);
  -o-transition: 0.5s -o-filter cubic-bezier(0, 0.88, .12, 1);
  transition: 0.5s filter cubic-bezier(0, 0.88, .12, 1);
}

.shadow:after {
  filter: blur(0px);
}

@keyframes animate {
  0% {
    background-position: 250% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 50% black background */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  /* Hidden by default */
  transition: opacity 0.3s ease;
  /* Smooth transition */

  &:hover {
    opacity: 1;
  }
}

.overlay p {
  color: white;
  text-align: center;
}